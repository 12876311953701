import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import Wallet from "./components/Wallet/Wallet";
import CustomerWallets from "./components/CustomerWallets/CustomerWallets";
import { useSelector, useDispatch } from "react-redux";
import {
  getWalletsFn,
  selectWalletState,
  getWalletBalanceFn,
  createWalletFn,
  // fundWalletFn,
} from "slices/walletSlice";
import Loading from "components/Loading/Loading";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateWalletSection, {
  CreateWalletValuesTypes,
} from "./components/CreateWallet/CreateWallet";
import WalletWithdraw from "./components/Withdraw/Withdraw";
import CustomerDetail from "screens/Customers/components/CustomerDetail/CustomerDetail";
import {
  selectCustomerState,
  getSingleCustomerFn,
} from "slices/customersSlice";

const HomeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const walletState = useSelector(selectWalletState);
  const {
    data: walletsData,
    meta: walletsMeta,
    loading: walletsLoading,
  } = walletState.wallets;
  const {
    data: walletBalanceData,
    loading: balanceLoading,
  } = walletState.balance;

  const createWalletLoading = walletState.createWallet.loading;
  const customersState = useSelector(selectCustomerState);
  const { customerData: customer } = customersState;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  // const [currentPage, setCurrentPage] = useState(walletsMeta.page);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = () => {
    dispatch(getWalletBalanceFn());
    dispatch(getWalletsFn({ page: currentPage }));
  };

  useEffect(fetchData, []);

  const renderSideContent = () => {
    if (sideContent === "createWallet") {
      return (
        <CreateWalletSection
          loading={createWalletLoading}
          createWalletFn={createWallet}
        />
      );
    } else if (sideContent === "walletWithdraw") {
      return <WalletWithdraw />;
    } else if (sideContent === "customerDetail") {
      return <CustomerDetail customer={customer} />;
    }
    return null;
  };

  const getCustomer = (id: string) => {
    openModal("customerDetail");
    dispatch(getSingleCustomerFn(id));
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const createWallet = (values: CreateWalletValuesTypes) => {
    dispatch(
      createWalletFn(values, () => {
        dispatch(getWalletsFn({ page: currentPage }));
        closeModal();
      })
    );
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const { id } = walletBalanceData;
  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {(!id && balanceLoading) || (!id && walletsLoading) ? (
          <Loading />
        ) : (
          <>
            <Wallet openSideContentFn={openModal} data={walletBalanceData} />
            <CustomerWallets
              meta={walletsMeta}
              data={walletsData}
              selectedWalletFn={getCustomer}
              pageClickFn={handlePageClick}
            />
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default HomeScreen;
