import * as React from "react";
import styles from "./SideNav.module.css";
import { NavLink } from "react-router-dom";
import route from "routing/routes";
import HomeImg from "assets/images/house.svg";
import TransactionsImg from "assets/images/clipboard.svg";
import CustomersImg from "assets/images/person.svg";
import SendMoneyImg from "assets/images/money-in.svg";
import SettingsImg from "assets/images/settings.svg";
import LogOutImg from "assets/images/logout.svg";
import { logoutFn } from "slices/authSlice";
import { useDispatch } from "react-redux";

const SideNav: React.FC = () => {
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logoutFn());
  };

  return (
    <div className={styles.sidenav}>
      <NavItem route={route.HOME} routeName="Home" image={HomeImg} />
      <NavItem
        route={route.CUSTOMERS}
        routeName="Customers"
        image={CustomersImg}
      />
      <NavItem
        route={route.TRANSACTIONS}
        routeName="Transactions"
        image={TransactionsImg}
      />

      <NavItem
        route={route.SEND_MONEY}
        routeName="Send Money"
        image={SendMoneyImg}
      />
      <NavItem
        route={route.SETTINGS}
        routeName="Settings"
        image={SettingsImg}
      />
      <div onClick={logoutUser} className={styles.sidenav__logoutContainer}>
        <img src={LogOutImg} alt="" />
        <span>Log Out</span>
      </div>
    </div>
  );
};

export default SideNav;

const NavItem = ({
  route,
  routeName,
  image,
}: {
  route: string;
  routeName: string;
  image: string;
}) => (
  <NavLink
    className={styles.sidenav__navItem}
    activeClassName={styles.sidenav__navItemActive}
    to={route}
  >
    <img src={image} alt="" />
    <span>{routeName}</span>
  </NavLink>
);
