import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import request from "constants/requests";

const initialState = {
  bankList: {
    loading: false,
    data: [],
    error: {},
  },
  bankAccountDetails: {
    loading: false,
    data: {},
    error: {},
  },
  bankTransfer: {
    loading: false,
    data: {},
    error: {},
  },
  userByPhone: {
    loading: false,
    data: {},
    error: {},
  },
};

const sendMoneySlice = createSlice({
  name: "sendMoney",
  initialState: initialState,
  reducers: {
    getBankList: (state) => {
      let { bankList } = state;
      bankList.loading = true;
      bankList.data = [];
      bankList.error = {};
    },
    getBankListSuccess: (state, { payload }) => {
      let { bankList } = state;
      bankList.loading = false;
      bankList.data = payload;
      bankList.error = {};
    },
    getBankListFailed: (state, { payload }) => {
      let { bankList } = state;
      bankList.loading = false;
      bankList.data = [];
      bankList.error = payload;
    },
    getBankAccountDetails: (state) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = true;
      bankAccountDetails.data = {};
      bankAccountDetails.error = {};
    },
    getBankAccountDetailsSuccess: (state, { payload }) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = false;
      bankAccountDetails.data = payload;
      bankAccountDetails.error = {};
    },
    getBankAccountDetailsFailed: (state, { payload }) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = false;
      bankAccountDetails.data = {};
      bankAccountDetails.error = payload;
    },
    postBankTransfer: (state) => {
      let { bankTransfer } = state;
      bankTransfer.loading = true;
      bankTransfer.data = {};
      bankTransfer.error = {};
    },
    postBankTransferSuccess: (state, { payload }) => {
      let { bankTransfer } = state;
      bankTransfer.loading = false;
      bankTransfer.data = payload;
      bankTransfer.error = {};
    },
    postBankTransferFailed: (state, { payload }) => {
      let { bankTransfer } = state;
      bankTransfer.loading = false;
      bankTransfer.data = {};
      bankTransfer.error = payload;
    },
    getUserByPhone: (state) => {
      let { userByPhone } = state;
      userByPhone.loading = true;
      userByPhone.data = {};
      userByPhone.error = {};
    },
    getUserByPhoneSuccess: (state, { payload }) => {
      let { userByPhone } = state;
      userByPhone.loading = false;
      userByPhone.data = payload;
      userByPhone.error = {};
    },
    getUserByPhoneFailed: (state, { payload }) => {
      let { userByPhone } = state;
      userByPhone.loading = false;
      userByPhone.data = {};
      userByPhone.error = payload;
    },
    clearUserByPhone: (state) => {
      let { userByPhone } = state;
      userByPhone.loading = false;
      userByPhone.data = {};
      userByPhone.error = {};
    },
    clearBankAccountDetails: (state) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = false;
      bankAccountDetails.data = {};
      bankAccountDetails.error = {};
    },
  },
});

export const {
  getBankList,
  getBankListSuccess,
  getBankListFailed,
  getBankAccountDetails,
  getBankAccountDetailsSuccess,
  getBankAccountDetailsFailed,
  postBankTransfer,
  postBankTransferSuccess,
  postBankTransferFailed,
  getUserByPhone,
  getUserByPhoneSuccess,
  getUserByPhoneFailed,
  clearUserByPhone,
  clearBankAccountDetails,
} = sendMoneySlice.actions;

export const getBankListFn = () => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(getBankList());
    const response = await request({
      method: "get",
      url: "/transfer/banks",
    });
    // console.log(response.data.banks);
    const bankList = response.data.banks;
    const sortBanksAlphabetically = (list: any) =>
      list.sort((a: any, b: any) => a.name.localeCompare(b.name));
    dispatch(getBankListSuccess(sortBanksAlphabetically(bankList)));
  } catch (error) {
    dispatch(getBankListFailed(error.response.data || error.response));
  }
};

export const getBankAccountDetailsFn = (
  sortCode: string,
  accountNumber: string
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(getBankAccountDetails());
    const response = await request({
      method: "get",
      url: "/transfer/account/details",
      params: {
        sortCode,
        accountNumber,
      },
    });
    dispatch(getBankAccountDetailsSuccess(response.data.account));
  } catch (error) {
    dispatch(
      getBankAccountDetailsFailed(error.response.data || error.response)
    );
  }
};

export const clearBankAccountDetailsFn = () => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(clearBankAccountDetails());
};

export const getUserByPhoneFn = (
  phoneNumber: string,
  cb?: (id: string) => void
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(getUserByPhone());
    const response = await request({
      method: "get",
      url: "/customer/phone",
      params: {
        phoneNumber,
      },
    });
    // console.log(response);

    dispatch(getUserByPhoneSuccess(response.data));
    if (cb) {
      cb(response.data.customer.id);
    }
  } catch (error) {
    dispatch(getUserByPhoneFailed(error.response.data || error.response));
  }
};

export const clearUserByPhoneFn = () => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(clearUserByPhone());
};

export const postBankTransferFn = (
  values: {
    amount: string;
    accountNumber: string;
    narration: string;
    sortCode: string;
    accountName: string;
  },
  cb?: () => void
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(postBankTransfer());
    const response = await request({
      method: "post",
      url: "/transfer/bank",
      data: values,
    });
    dispatch(postBankTransferSuccess(response.data));
    toast.success(response.data.message || "Success");
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch(postBankTransferFailed(error.response.data || error.response));
  }
};

export const selectSendMoneyState = (state: any) => state.sendMoney;

export default sendMoneySlice.reducer;
