import React from "react";
import Header from "components/Header/Header";
import styles from "./CustomerWallets.module.css";
import Table from "components/Table/Table";
import { numberWithCommas } from "utils/helpers";
// import ReactPaginate from "react-paginate";

interface CustomerWallets {
  data: object[];
  selectedWalletFn: any;
  meta: any;
  pageClickFn: any;
}

const CustomerWallets: React.FC<CustomerWallets> = ({
  data,
  selectedWalletFn,
  meta,
  pageClickFn,
}) => {
  const renderRows = () => {
    const rows = data.map((each: any, index: number) => {
      return (
        <tr
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectedWalletFn(each.customerId);
          }}
          key={index}
        >
          <td>{new Date(each.createdAt).toLocaleDateString("en-gb")}</td>
          <td>{each.accountName}</td>
          <td>{each.bankName}</td>
          <td>&#8358;{numberWithCommas(each.availableBalance)}</td>
          <td>{each.accountNumber}</td>
          <td>{each.email}</td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <div className={styles.customerWallet}>
      <div className="mb-4">
        <Header>Customer Wallet</Header>
      </div>
      <div className={styles.customerWallet__content}>
        <Table
          noData={data.length === 0 ? true : false}
          headers={[
            "created",
            "account name",
            "bank",
            "balance",
            "account number",
            "email",
          ]}
        >
          {renderRows()}
        </Table>
        {/* <nav aria-label="Page navigation example">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            // breakLabel={"..."}
            // breakClassName={"break-me"}
            pageCount={meta.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={pageClickFn}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item"}
          />
        </nav> */}
      </div>
    </div>
  );
};

export default CustomerWallets;
