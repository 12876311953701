export default {
  REGISTER: "/register",
  VERIFICATION: "/verification",
  HOME: "/home",
  CUSTOMERS: "/customers",
  TRANSACTIONS: "/transactions",
  SEND_MONEY: "/send-money",
  SETTINGS: "/settings",
  LOGIN: "/",
  PROFILE: "/settings/profile",
  SECURITY: "/settings/security",
  ACCOUNT_VERIFICATION: "/settings/account-verification",
  NOTIFICATIONS: "/settings/notifications",
  DEVELOPER_KEYS: "/settings/developer-keys",
  VIA_WALLET: "/send-money/via-wallet",
  VIA_BANK: "/send-money/via-bank",
  VIA_MOBILE_MONEY: "/send-money/via-mobile-money",
  KYC: "/kyc",
  ACCOUNT_REVIEW: "/account-review",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
};
