import React from "react";
import styles from "./ModalBackground.module.css";

interface ModalBackgroundProps {
  modalIsOpen: boolean;
  closeModalFn: () => void;
}

const ModalBackground: React.FC<ModalBackgroundProps> = ({
  modalIsOpen,
  closeModalFn,
}) => {
  return (
    <div
      onClick={closeModalFn}
      className={modalIsOpen ? styles.background : styles.noDisplay}
    ></div>
  );
};

export default ModalBackground;
