import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./Customers.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import {
  getCustomersFn,
  selectCustomerState,
  getSingleCustomerFn,
} from "slices/customersSlice";
import { useSelector, useDispatch } from "react-redux";
import Loading from "components/Loading/Loading";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import ReactPaginate from "react-paginate";
import { getUserByPhoneFn } from "slices/sendMoneySlice";
import CreateWalletSection, {
  CreateWalletValuesTypes,
} from "screens/Home/components/CreateWallet/CreateWallet";
import { selectWalletState, createWalletFn } from "slices/walletSlice";
import AddWalletImg from "assets/images/addWallet.svg";

const CustomersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const customersState = useSelector(selectCustomerState);
  // All customers
  const {
    data: customersData,
    meta: customersMeta,
    loading: customersLoading,
  } = customersState.customers;
  // Single customer
  const { customerData: customer } = customersState;
  const walletState = useSelector(selectWalletState);
  const createWalletLoading = walletState.createWallet.loading;

  const [currentPage, setCurrentPage] = useState(customersMeta.page);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const fetchCustomers = () => {
    dispatch(getCustomersFn({ page: currentPage }));
  };

  useEffect(fetchCustomers, [currentPage]);

  const renderRows = () => {
    const rows = customersData.map((each: any) => {
      return (
        <tr
          style={{ cursor: "pointer" }}
          key={each.id}
          onClick={() => getCustomer(each.id)}
        >
          <td>{new Date(each.createdAt).toLocaleDateString("en-gb")}</td>
          <td>
            {each.lastName} {each.firstName}
          </td>
          <td>{each.dateOfBirth}</td>
          <td>{each.phoneNumber}</td>
          <td>{each.email}</td>
        </tr>
      );
    });
    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const createWallet = (values: CreateWalletValuesTypes) => {
    dispatch(
      createWalletFn(values, () => {
        fetchCustomers();
        closeModal();
      })
    );
  };

  const renderSideContent = () => {
    if (sideContent === "customerDetail") {
      return <CustomerDetail closeModalFn={closeModal} customer={customer} />;
    }
    if (sideContent === "createWallet") {
      return (
        <CreateWalletSection
          loading={createWalletLoading}
          createWalletFn={createWallet}
        />
      );
    } else {
      return null;
    }
  };

  const getCustomer = (id: string) => {
    openModal("customerDetail");
    dispatch(getSingleCustomerFn(id));
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const findUserByPhone = (phoneNumber: string) => {
    dispatch(
      getUserByPhoneFn(phoneNumber, (id) => {
        getCustomer(id);
      })
    );
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {customersData.length < 1 && customersLoading ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>
                Customers
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => openModal("createWallet")}
                  className="ml-2"
                  src={AddWalletImg}
                  alt=""
                />
              </Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of customers
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {customersMeta.totalRecords || 0}
                </h4>
              </div>
            </div>
            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  findUserByPhone(searchValue);
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search customer
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div
                        style={{
                          borderRightColor: "transparent",
                          backgroundColor: "transparent",
                        }}
                        className="input-group-text"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                      style={{ borderLeftColor: "transparent" }}
                      type="text"
                      className="form-control"
                      id="searchCustomer"
                      placeholder="Search with customer’s phone number"
                    />
                  </div>
                </div>
              </form>
              <Table
                noData={customersData.length === 0 ? true : false}
                headers={[
                  "created",
                  "name",
                  "date of birth",
                  "phone number",
                  "email",
                ]}
              >
                {renderRows()}
              </Table>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  // breakLabel={"..."}
                  // breakClassName={"break-me"}
                  pageCount={customersMeta.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                />
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default CustomersScreen;
