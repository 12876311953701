import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Card from "./components/Card/Card";

interface Props {
  title?: string;
}

const AuthLayout: React.FC<Props> = ({ children, title }) => {
  return (
    <div>
      <Navbar />
      <Card title={title}>{children}</Card>
    </div>
  );
};

export default AuthLayout;
