import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "nprogress/nprogress.css";
import Routes from "./routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";

export const dispatch = store.dispatch;
export const selector = store.getState;

function App() {
    return (
        <Provider store={store}>
            <ToastContainer autoClose={10000} />
            <Routes />
        </Provider>
    );
}

export default App;
