import React, { Fragment, useEffect } from "react";
import { mixed, object, string } from "yup";
import { Field, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import routes from "routing/routes";

import Settings from "../Settings";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import { updateKycFn, selectAuthState } from "slices/authSlice";
import { getProfileFn, selectSettingsState } from "slices/settingsSlice";

const AccountVerificationScreen: React.FC = () => {
    const history = useHistory();

    const fetchProfile = () => {
        dispatch(getProfileFn());
    };

    useEffect(fetchProfile, []);

    const dispatch = useDispatch();
    const authState = useSelector(selectAuthState);
    const profileState = useSelector(selectSettingsState);
    const { data: user, loading: userLoading } = profileState.getProfile;

    const { loading: kycLoading } = authState.kyc;

    const submitKYC = (values: any) => {
        console.log({ values });

        dispatch(
            updateKycFn({
                bvn: values.bvn,
                image: values.file,
                imageTitle: values.file.name,
                cb: () => history.push(routes.ACCOUNT_REVIEW),
            })
        );
    };

    const fileSelectedHandler = (event: any, setFieldValue: any) => {
        const file = event.target.files[0];

        if (!file) return;

        if (file) {
            setFieldValue("file", file);
        }
    };

    const renderContent = () => {
        if (userLoading && !user.id) {
            return <Loading />;
        } else if (user.review === "ENABLED") {
            return (
                <div>
                    <p className="mb-0">Identity Verification</p>
                    <p className="settings__lightText">Your ID Card document has been verified.</p>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-6">
                        <h2 className="mb-2">Director's Details</h2>
                        <p className="settings__lightText mb-3">
                            Submit the KYC(Know Your Customer) information to verifiy you account and complete
                            registration
                        </p>
                        <Formik
                            onSubmit={submitKYC}
                            initialValues={{ bvn: "", file: null }}
                            validationSchema={object().shape({
                                file: mixed().required("Select document"),
                                bvn: string().required("Bvn is required.").length(11, "Must be 11 characters"),
                            })}
                        >
                            {({ values, errors, setFieldValue }: any) => (
                                <Form>
                                    <Field name="bvn" placeholder="BVN" type="text" component={TextInput} />

                                    <div className="form-group">
                                        <label htmlFor="cac">Upload CAC Pack</label>

                                        <div className="custom-file">
                                            <input
                                                id="cac"
                                                type="file"
                                                className="custom-file-input"
                                                onChange={(e) => fileSelectedHandler(e, setFieldValue)}
                                                accept="image/png, image/jpeg,image/jpg,.pdf"
                                            />
                                            <label className="custom-file-label" htmlFor="cac">
                                                {values?.file?.name || "Choose file"}
                                            </label>

                                            {errors.file && (
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {errors.file}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {kycLoading ? (
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <PrimaryButton
                                                type="submit"
                                                block
                                                // size="lg"
                                                title="Submit"
                                            />
                                        </Fragment>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            );
        }
    };
    return <Settings>{renderContent()}</Settings>;
};

export default AccountVerificationScreen;
