import React from "react";
import AuthLayout from "layouts/Auth/Auth";
import { Formik, Form, Field } from "formik";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthState, changePasswordFn } from "slices/authSlice";
import Loading from "components/Loading/Loading";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

export interface ChangePasswordScreenProps {
  history: any;
}

const ChangePasswordScreen: React.FC<ChangePasswordScreenProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const { loading: changePasswordLoading } = authState.changePassword;

  return (
    <AuthLayout title="Change Password">
      <Formik
        initialValues={{
          resetCode: "",
          password: "",
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values) => {
          // submitForm(values);
          dispatch(changePasswordFn(values, () => history.push("/")));
        }}
      >
        {() => (
          <Form>
            <Field
              name="resetCode"
              placeholder="Reset Code"
              type="text"
              component={TextInput}
            />
            <Field
              autoComplete="new-password"
              name="password"
              placeholder="New Password"
              type="password"
              component={TextInput}
            />
            {changePasswordLoading ? (
              <Loading />
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Reset Password"
              />
            )}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default withRouter(ChangePasswordScreen);

const ChangePasswordSchema = Yup.object().shape({
  resetCode: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
});
