import * as React from "react";
import AuthLayout from "layouts/Auth/Auth";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import { Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  verifyUserFn,
  selectAuthState,
  VerificationValuesTypes,
  resendVerificationFn,
} from "slices/authSlice";
import * as Yup from "yup";
import routes from "routing/routes";
import { withRouter } from "react-router-dom";
import TextInput from "components/TextInput/TextInput";

const VerificationScreen: React.FC<{ history: any; match: any }> = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const { loading: verificationLoading } = authState.verification;
  const { loading: resendVerificationLoading } = authState.resendVerification;

  const submitForm = (values: VerificationValuesTypes) => {
    dispatch(verifyUserFn(values, () => props.history.push(routes.LOGIN)));
  };

  const resendVerificationCode = () => {
    const email = props.match.params.email;
    dispatch(resendVerificationFn(email));
  };

  return (
    <AuthLayout title="Verification">
      <Formik
        initialValues={{
          activationCode: "",
        }}
        validationSchema={VerificationSchema}
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {() => (
          <Form>
            <Field
              label="Enter verification code"
              id="code"
              name="activationCode"
              placeholder=""
              type="text"
              component={TextInput}
            />
            {verificationLoading || resendVerificationLoading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <PrimaryButton type="submit" title="Verify" block size="lg" />
                <OutlineButton
                  onClick={resendVerificationCode}
                  title="Resend Code"
                  block
                  size="lg"
                />
              </React.Fragment>
            )}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default withRouter(VerificationScreen);

const VerificationSchema = Yup.object().shape({
  activationCode: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
});
