import React, { useEffect, Fragment } from "react";
import styles from "./Bank.module.css";
import { PrimaryButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getBankListFn,
  // getBankAccountDetailsFn,
  selectSendMoneyState,
  postBankTransferFn,
  getBankAccountDetailsFn,
  clearBankAccountDetailsFn,
} from "slices/sendMoneySlice";
import { useSelector, useDispatch } from "react-redux";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";

const ViaBank: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectSendMoneyState);
  const { loading: bankListLoading } = bankState.bankList;
  const { loading: bankTransferloading } = bankState.bankTransfer;
  const {
    data: bankAccountDetails,
    loading: bankAccountDetailsLoading,
  } = bankState.bankAccountDetails;

  const fetchData = () => {
    dispatch(getBankListFn());
  };

  useEffect(fetchData, []);

  const fetchBankAcount = (values: any) => {
    const { accountNumber, sortCode } = values;
    if (
      accountNumber &&
      Number(accountNumber) &&
      accountNumber?.length === 10 &&
      sortCode
    ) {
      dispatch(getBankAccountDetailsFn(sortCode, accountNumber));
    } else if (accountNumber?.length !== 10) {
      dispatch(clearBankAccountDetailsFn());
    }
  };

  const renderVerifiedBankAccount = () => {
    if (bankAccountDetailsLoading) {
      return "verifiying...";
    } else if (bankAccountDetails.accountName) {
      return `${bankAccountDetails.accountName}`;
    } else {
      return null;
    }
  };

  const renderBankList = () => {
    const { data: allBanks } = bankState.bankList;
    const banks = allBanks.map((bank: { code: string; name: string }) => {
      return (
        <option value={bank.code} key={bank.code}>
          {bank.name}
        </option>
      );
    });
    return banks;
  };

  return (
    <SendMoney>
      <div className={styles.viaBank}>
        {bankListLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <h4 className={styles.viaBank__title}>Enter recipients details</h4>
            <Formik
              // enableReinitialize
              initialValues={{
                amount: "",
                accountNumber: "",
                narration: "",
                sortCode: "",
              }}
              validationSchema={BankTransferSchema}
              onSubmit={(values, actions) => {
                // console.log(values);
                dispatch(
                  postBankTransferFn(
                    {
                      ...values,
                      accountName: bankAccountDetails.accountName,
                    },
                    () => {
                      actions.resetForm();
                      dispatch(clearBankAccountDetailsFn());
                    }
                  )
                );

                // submitForm(values);
              }}
            >
              {(props) => {
                const sortCodeClassName = `form-control form-control-lg ${
                  props.errors.sortCode && props.touched.sortCode
                    ? "is-invalid"
                    : ""
                }`;

                return (
                  <Form>
                    <div className="form-group">
                      <Field
                        // validate={BankTransferSchema.fields?.sortCode}
                        name="sortCode"
                        component="select"
                        className={sortCodeClassName}
                        onBlur={() => {
                          fetchBankAcount(props.values);
                        }}
                      >
                        <option value="" disabled hidden>
                          Select Bank
                        </option>
                        {renderBankList()}
                      </Field>
                      <ErrorMessage name="sortCode">
                        {(error) => (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <Field
                      onBlur={(e: { currentTarget: { value: any } }) => {
                        props.handleBlur(e);
                        fetchBankAcount(props.values);
                      }}
                      name="accountNumber"
                      placeholder="Account Number"
                      type="text"
                      component={TextInput}
                      subtext={renderVerifiedBankAccount()}
                    />
                    <Field
                      name="amount"
                      placeholder="Amount"
                      type="number"
                      component={TextInput}
                    />
                    <Field
                      name="narration"
                      placeholder="Narration (optional)"
                      type="text"
                      component={TextInput}
                    />

                    {bankTransferloading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <PrimaryButton
                        type="submit"
                        block
                        size="lg"
                        title="Transfer"
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        )}
      </div>
    </SendMoney>
  );
};

export default ViaBank;

const BankTransferSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  amount: Yup.string().required("Required"),
  sortCode: Yup.string().required("Required"),
});
