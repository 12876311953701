import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import request from "constants/requests";

// const baseUrl = config.API_URL;

const initialState = {
  transactions: {
    loading: false,
    data: [],
    error: {},
    meta: {},
  },
  singleTransaction: {
    loading: false,
    data: {},
    error: {},
  },
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialState,
  reducers: {
    getTransactions: (state) => {
      let { transactions } = state;
      transactions.loading = true;
      // transactions.data = [];
      transactions.error = {};
      // transactions.meta = {};
    },
    getTransactionsSuccess: (state, { payload }) => {
      let { transactions } = state;
      transactions.loading = false;
      transactions.data = payload.data;
      transactions.error = {};
      transactions.meta = payload.meta;
    },
    getTransactionsFailed: (state, { payload }) => {
      let { transactions } = state;
      transactions.loading = false;
      transactions.data = [];
      transactions.error = payload;
      transactions.meta = {};
    },
    getSingleTransaction: (state) => {
      let { singleTransaction } = state;
      singleTransaction.loading = true;
      singleTransaction.data = {};
      singleTransaction.error = {};
    },
    getSingleTransactionSuccess: (state, { payload }) => {
      let { singleTransaction } = state;
      singleTransaction.loading = false;
      singleTransaction.data = payload;
      singleTransaction.error = {};
    },
    getSingleTransactionFailed: (state, { payload }) => {
      let { singleTransaction } = state;
      singleTransaction.loading = false;
      singleTransaction.data = {};
      singleTransaction.error = payload;
    },
  },
});

export const {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
  getSingleTransaction,
  getSingleTransactionSuccess,
  getSingleTransactionFailed,
} = transactionSlice.actions;

export const getTransactionsFn = ({ page }: { page: number }) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(getTransactions());

    const response = await request({
      method: "get",
      url: "/merchant/transactions",
      params: {
        page,
        type: "ALL",
      },
    });

    dispatch(
      getTransactionsSuccess({
        data: response.data.transactions,
        meta: response.data.metadata,
      })
    );
    // console.log(response.data);
  } catch (error) {
    dispatch(getTransactionsFailed(error.response.data || error.response));
  }
};
export const getSingleTransactionFn = (
  reference: string,
  cb?: () => void
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(getSingleTransaction());

    const response = await request({
      method: "get",
      url: `/merchant/transaction/${reference}`,
    });

    dispatch(getSingleTransactionSuccess(response.data.transaction));
    if (cb) {
      cb();
    }
    // console.log(response.data);
  } catch (error) {
    dispatch(getSingleTransactionFailed(error.response.data || error.response));
  }
};

export const selectTransactionsState = (state: any) => state.transactions;

export default transactionSlice.reducer;
