import * as React from "react";
import styles from "./BankDetails.module.css";

export interface BankDetailsProps {
  accountName: string;
  accountNumber: string;
  bankName: string;
}

const BankDetails: React.SFC<BankDetailsProps> = ({
  accountName,
  accountNumber,
  bankName,
}) => {
  return (
    <div className={styles.bankDetails__accountInfo}>
      <div>
        <p className={styles.bankDetails__accountTitle}>Account Name</p>
        <p className={styles.bankDetails__accountValue}>{accountName}</p>
      </div>
      <div className={styles.bankDetails__hl} />
      <div className="d-flex">
        <div className="mr-4">
          <p className={styles.bankDetails__accountTitle}>Bank</p>
          <p className={styles.bankDetails__accountValue}>{bankName}</p>
        </div>
        <div>
          <p className={styles.bankDetails__accountTitle}>Account Number</p>
          <p className={styles.bankDetails__accountValue}>{accountNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
