import * as React from "react";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./SendMoney.module.css";
import Header from "components/Header/Header";
import NavSection from "components/CardNav/CardNav";
import SectionSelector from "./components/SectionSelector/SectionSelector";
import routes from "routing/routes";

const navLinks = [
  {
    label: "Via Wallet Account",
    route: routes.SEND_MONEY,
  },
  {
    label: "Via Bank Account",
    route: routes.VIA_BANK,
  },
  // {
  //   label: "Via Mobile Money",
  //   route: routes.VIA_MOBILE_MONEY,
  // },
];

const SendMoney: React.FC = ({ children }) => {
  return (
    <DashboardLayout>
      <Header>Send Money</Header>
      <div className={`mt-4 ${styles.content__container}`}>
        <NavSection links={navLinks} />
        <div className={styles.content}>
          <SectionSelector />
          {children}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SendMoney;
