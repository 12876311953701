import * as React from "react";
import SendMoney from "../../SendMoney";

const ViaMobileMoney: React.FC = () => {
  return (
    <SendMoney>
      <div>Bank</div>
    </SendMoney>
  );
};

export default ViaMobileMoney;
