import * as React from "react";
import LogoImg from "assets/images/logo.svg";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  return (
    <nav className={styles.navbar}>
      <div className="container">
        <Link to="/">
          <img className={styles.navbar__logo} src={LogoImg} alt="logo" />
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
