import * as React from "react";
import styles from "./SideContent.module.css";

interface SideContentProps {
  modalIsOpen: boolean;
  closeModalFn: () => void;
}

const SideContent: React.FC<SideContentProps> = ({
  modalIsOpen,
  closeModalFn,
  children,
}) => {
  return (
    <div className={modalIsOpen ? styles.sideContent : styles.noDisplay}>
      {children}
    </div>
  );
};

export default SideContent;
