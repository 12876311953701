import React, { Fragment } from "react";
import AuthLayout from "layouts/Auth/Auth";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import routes from "routing/routes";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  registerUserFn,
  selectAuthState,
  RegisterValuesTypes,
} from "slices/authSlice";
import styles from "./Register.module.css";
import { withRouter } from "react-router-dom";
import TextInput from "components/TextInput/TextInput";

const RegisterScreen: React.FC<{ history: any }> = () => {
  const dispatch = useDispatch();
  const registerState = useSelector(selectAuthState);

  const submitForm = (values: RegisterValuesTypes) => {
    dispatch(registerUserFn(values));
  };

  return (
    <AuthLayout title="Registration">
      <Formik
        // enableReinitialize
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phoneNumber: "",
          businessName: "",
          businessType: "",
          agreement: "",
        }}
        validationSchema={RegisterSchema}
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {(props) => {
          const businessTypeClassname = `form-control form-control-lg ${
            props.errors.businessType && props.touched.businessType
              ? "is-invalid"
              : ""
          }`;
          // const merchantTypeClassname = `form-control form-control-lg ${
          //   props.errors.merchantType && props.touched.merchantType
          //     ? "is-invalid"
          //     : ""
          // }`;
          return (
            <Form>
              <div className="row">
                <div className="col-6">
                  <Field
                    name="firstName"
                    placeholder="First Name*"
                    type="text"
                    component={TextInput}
                  />
                </div>
                <div className="col-6">
                  <Field
                    name="lastName"
                    placeholder="Last Name*"
                    type="text"
                    component={TextInput}
                  />
                </div>
              </div>
              <Field
                name="email"
                placeholder="Email Address*"
                type="email"
                component={TextInput}
              />
              <Field
                name="phoneNumber"
                placeholder="Phone Number*"
                type="tel"
                autoComplete="tel"
                component={TextInput}
              />
              <Field
                name="password"
                placeholder="Password*"
                type="password"
                autoComplete="new-password"
                component={TextInput}
              />
              <Field
                name="businessName"
                placeholder="Business Name*"
                type="text"
                component={TextInput}
              />

              <div className="form-group">
                <Field
                  name="businessType"
                  component="select"
                  placeholder="Favorite Color"
                  className={businessTypeClassname}
                  // defaultValue=""
                >
                  <option value="" disabled hidden>
                    Select Business
                  </option>
                  <option>FINANCIAL-SERVICES</option>
                  <option>INFORMATION-TECHNOLOGY</option>
                </Field>
                <ErrorMessage name="businessType">
                  {(error) => <div className="invalid-feedback">{error}</div>}
                </ErrorMessage>
              </div>

              {/* <div className="form-group">
                <Field
                  name="merchantType"
                  component="select"
                  className={merchantTypeClassname}
                >
                  <option value="" disabled hidden>
                    Select Merchant Type
                  </option>
                  <option>PLATINUM</option>
                  <option>GOLD</option>
                </Field>
                <ErrorMessage name="merchantType">
                  {(error) => <div className="invalid-feedback">{error}</div>}
                </ErrorMessage>
              </div>
              {props.values.merchantType === "GOLD" ? (
                <div>
                  <Field
                    name="contractCode"
                    placeholder="Contract Code*"
                    type="text"
                    component={TextInput}
                  />
                  <Field
                    name="secretKey"
                    placeholder="Secret Key*"
                    type="text"
                    component={TextInput}
                  />
                  <Field
                    name="apiKey"
                    placeholder="Api Key*"
                    type="text"
                    component={TextInput}
                  />
                </div>
              ) : null} */}

              <Field name="agreement">
                {({ field, form, meta }: any) => (
                  <div className="form-group form-check  ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="agreement"
                      {...field}
                    />
                    <label className="form-check-label" htmlFor="agreement">
                      I agree to be contacted by Glide about this offer as per
                      the Glide Privacy Policy.
                    </label>
                    {meta.touched && meta.error && (
                      <div className={styles.error}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>

              {registerState.register.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <PrimaryButton
                    type="submit"
                    block
                    size="lg"
                    title="Get Started"
                  />
                  <OutlineButton
                    block
                    size="lg"
                    link={routes.LOGIN}
                    title="Login"
                  />
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default withRouter(RegisterScreen);

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  phoneNumber: Yup.string()
    .min(11, "Too Short!")
    .max(11, "Too Long!")
    .required("Required"),
  businessName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  businessType: Yup.string().required("Required"),
  // merchantType: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  // contractCode: Yup.string().required("Required"),
  // secretKey: Yup.string().required("Required"),
  // apiKey: Yup.string().required("Required"),
  agreement: Yup.boolean().required("You must accept agreement"),
});
