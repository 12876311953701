import React, { useState } from "react";
import BankDetails from "components/BankDetails/BankDetails";
import Loading from "components/Loading/Loading";
import styles from "./CustomerDetail.module.css";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import AmountModal from "../AmountModal/AmountModal";
import {
  debitWalletFn,
  creditWalletFn,
  getSingleCustomerFn,
  freezeWalletFn,
  unfreezeWalletFn,
} from "slices/customersSlice";
import { useDispatch } from "react-redux";
import { numberWithCommas } from "utils/helpers";

export interface CustomerDetailProps {
  customer: {
    loading: boolean;
    data: any;
    error: any;
  };
  closeModalFn?: () => void | undefined;
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({
  customer,
  closeModalFn,
}) => {
  const [amountModalIsOpen, setAmountModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const dispatch = useDispatch();

  const debitWallet = (values: { amount: string }) => {
    dispatch(
      debitWalletFn(
        {
          amount: values.amount,
          customerId: customer.data.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const creditWallet = (values: { amount: string }) => {
    dispatch(
      creditWalletFn(
        {
          amount: values.amount,
          customerId: customer.data.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const openModal = (content: string) => {
    setModalContent(content);
    setAmountModalIsOpen(true);
  };

  const closeModal = () => {
    setAmountModalIsOpen(false);
    setModalContent("");
  };

  const renderModalContent = () => {
    if (modalContent === "creditWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={creditWallet}
        />
      );
    } else if (modalContent === "debitWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={debitWallet}
        />
      );
    }
  };

  const renderWalletActivation = ({
    customerId,
    status,
    closeModal,
  }: {
    customerId: string;
    status: string;
    closeModal: () => void;
  }) => {
    if (status === "ACTIVE") {
      return (
        <button
          onClick={() => {
            dispatch(
              freezeWalletFn(customerId, () => {
                if (closeModalFn) closeModalFn();
              })
            );
          }}
          style={{ color: "var(--purple-color)" }}
          type="button"
          className="btn btn-light btn-block"
        >
          Freeze wallet
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            dispatch(
              unfreezeWalletFn(customerId, () => {
                if (closeModalFn) closeModalFn();
              })
            );
          }}
          style={{ color: "var(--purple-color)" }}
          type="button"
          className="btn btn-light btn-block"
        >
          Unfreeze wallet
        </button>
      );
    }
  };

  const {
    email,
    status,
    bookedBalance,
    availableBalance,
    // accountReference,
    accountName,
    accountNumber,
    bankName,
    lastName,
    firstName,
    customerId,
  } = customer.data;

  if (customer.loading) {
    return <Loading />;
  }

  return (
    <div>
      <ModalBackground
        modalIsOpen={amountModalIsOpen}
        closeModalFn={closeModal}
      />
      {renderModalContent()}
      <div>
        <p className={styles.customerDetail__fullName}>
          {lastName} {firstName}
        </p>
        <p className={styles.customerDetail__email}>{email}</p>
      </div>
      <BankDetails
        accountName={accountName}
        accountNumber={accountNumber}
        bankName={bankName}
      />

      <table className="table table-striped table-borderless mt-4">
        <tbody>
          <tr>
            <td>Email</td>
            <td align="right">{email}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td align="right">{status}</td>
          </tr>
          <tr>
            <td>Book Balance</td>
            <td align="right">&#8358;{numberWithCommas(bookedBalance)}</td>
          </tr>
          <tr>
            <td>Available Balance</td>
            <td align="right">&#8358;{numberWithCommas(availableBalance)}</td>
          </tr>
          {/* <tr>
            <td>Reference</td>
            <td align="right">{accountReference}</td>
          </tr> */}
        </tbody>
      </table>
      <PrimaryButton
        onClick={() => openModal("creditWallet")}
        block
        title="Credit Wallet"
      />
      <OutlineButton
        onClick={() => openModal("debitWallet")}
        block
        title="Debit Wallet"
      />
      {renderWalletActivation({
        customerId,
        status,
        closeModal: () => closeModal(),
      })}
    </div>
  );
};

export default CustomerDetail;
