import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Button/Button";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  bvn: string;
  dateOfBirth: string;
}

export interface CreateWalletSectionProps {
  createWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
}

const CreateWalletSection: React.SFC<CreateWalletSectionProps> = ({
  createWalletFn,
  loading,
}) => {
  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };
  return (
    <div>
      <h2 className="mb-5">Create Wallet</h2>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          dateOfBirth: "",
          bvn: "",
        }}
        validationSchema={CreateWalletSchema}
        onSubmit={(values, actions) => {
          createWalletFn(values);
          actions.resetForm();

          // console.log(values);
        }}
      >
        {() => (
          <Form>
            <div className="row">
              <div className="col">
                <Field
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  component={TextInput}
                />
              </div>
              <div className="col">
                <Field
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  component={TextInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="email"
                  placeholder="Email"
                  type="email"
                  component={TextInput}
                />
              </div>
              <div className="col">
                <Field
                  name="phoneNumber"
                  placeholder="Phone"
                  type="tel"
                  component={TextInput}
                />
              </div>
            </div>
            <Field
              name="bvn"
              placeholder="BVN*"
              type="text"
              component={TextInput}
            />
            <Field
              name="dateOfBirth"
              placeholder="YYYY-MM-DD"
              type="date"
              component={TextInput}
            />

            {loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Create Wallet"
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateWalletSection;

const CreateWalletSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  phoneNumber: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  // bvn: Yup.string()
  //   .min(3, "Too Short!")
  //   .max(70, "Too Long!")
  //   .required("Required"),
  dateOfBirth: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});
