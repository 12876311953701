import { createSlice, Dispatch } from "@reduxjs/toolkit";
import config from "constants/api";
import request from "constants/requests";
import routes from "routing/routes";
import { toast } from "react-toastify";

export interface RegisterValuesTypes {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    businessName: string;
    businessType?: string;
    merchantType?: string;
    contractCode?: string;
    secretKey?: string;
    apiKey?: string;
}

export interface VerificationValuesTypes {
    activationCode: string;
}

export interface LoginValuesTypes {
    email: string;
    password: string;
}

const initialState = {
    token: { refreshToken: null },
    login: { loading: false, error: {}, response: {} },
    register: { loading: false, error: {}, response: {} },
    verification: { loading: false, error: {}, response: {} },
    resendVerification: { loading: false, error: {}, response: {} },
    logout: { loading: false, error: {}, response: {} },
    kyc: { loading: false, error: {}, response: {} },
    forgotPassword: { loading: false, error: {}, response: {} },
    changePassword: { loading: false, error: {}, response: {} },
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setRefreshToken: (state, { payload }) => {
            state.token.refreshToken = payload;
        },
        login: (state) => {
            let { login } = state;
            login.loading = true;
            login.response = initialState.login.response;
            login.error = initialState.login.error;
        },
        loginSuccess: (state, { payload }) => {
            let { login } = state;
            login.loading = false;
            login.response = payload;
            login.error = initialState.login.error;
        },
        loginFailed: (state, { payload }) => {
            let { login } = state;
            login.loading = false;
            login.response = initialState.login.response;
            login.error = payload;
        },
        register: (state) => {
            let { register } = state;
            register.loading = true;
            register.response = initialState.register.response;
            register.error = initialState.register.error;
        },
        registerSuccess: (state, { payload }) => {
            let { register } = state;
            register.loading = false;
            register.response = payload;
            register.error = initialState.register.error;
        },
        registerFailed: (state, { payload }) => {
            let { register } = state;
            register.loading = false;
            register.response = initialState.register.response;
            register.error = payload;
        },
        verification: (state) => {
            let { verification } = state;
            verification.loading = true;
            verification.response = initialState.verification.response;
            verification.error = initialState.verification.error;
        },
        verificationSuccess: (state, { payload }) => {
            let { verification } = state;
            verification.loading = false;
            verification.response = payload;
            verification.error = initialState.verification.error;
        },
        verificationFailed: (state, { payload }) => {
            let { verification } = state;
            verification.loading = false;
            verification.response = initialState.verification.response;
            verification.error = payload;
        },
        resendVerification: (state) => {
            let { resendVerification } = state;
            resendVerification.loading = true;
            resendVerification.response = initialState.resendVerification.response;
            resendVerification.error = initialState.resendVerification.error;
        },
        resendVerificationSuccess: (state, { payload }) => {
            let { resendVerification } = state;
            resendVerification.loading = false;
            resendVerification.response = payload;
            resendVerification.error = initialState.resendVerification.error;
        },
        resendVerificationFailed: (state, { payload }) => {
            let { resendVerification } = state;
            resendVerification.loading = false;
            resendVerification.response = initialState.resendVerification.response;
            resendVerification.error = payload;
        },
        logout: (state) => {
            let { logout } = state;
            logout.loading = true;
            logout.response = initialState.logout.response;
            logout.error = initialState.logout.error;
        },
        logoutSuccess: (state, { payload }) => {
            let { logout } = state;
            logout.loading = false;
            logout.response = payload;
            logout.error = initialState.logout.error;
        },
        logoutFailed: (state, { payload }) => {
            let { logout } = state;
            logout.loading = false;
            logout.response = initialState.logout.response;
            logout.error = payload;
        },
        updateKyc: (state) => {
            let { kyc } = state;
            kyc.loading = true;
            kyc.response = initialState.kyc.response;
            kyc.error = initialState.kyc.error;
        },
        updateKycSuccess: (state, { payload }) => {
            let { kyc } = state;
            kyc.loading = false;
            kyc.response = payload;
            kyc.error = initialState.kyc.error;
        },
        updateKycFailed: (state, { payload }) => {
            let { kyc } = state;
            kyc.loading = false;
            kyc.response = initialState.kyc.response;
            kyc.error = payload;
        },
        forgotPassword: (state) => {
            let { forgotPassword } = state;
            forgotPassword.loading = true;
            forgotPassword.response = initialState.forgotPassword.response;
            forgotPassword.error = initialState.forgotPassword.error;
        },
        forgotPasswordSuccess: (state, { payload }) => {
            let { forgotPassword } = state;
            forgotPassword.loading = false;
            forgotPassword.response = payload;
            forgotPassword.error = initialState.forgotPassword.error;
        },
        forgotPasswordFailed: (state, { payload }) => {
            let { forgotPassword } = state;
            forgotPassword.loading = false;
            forgotPassword.response = initialState.forgotPassword.response;
            forgotPassword.error = payload;
        },
        changePassword: (state) => {
            let { changePassword } = state;
            changePassword.loading = true;
            changePassword.response = initialState.changePassword.response;
            changePassword.error = initialState.changePassword.error;
        },
        changePasswordSuccess: (state, { payload }) => {
            let { changePassword } = state;
            changePassword.loading = false;
            changePassword.response = payload;
            changePassword.error = initialState.changePassword.error;
        },
        changePasswordFailed: (state, { payload }) => {
            let { changePassword } = state;
            changePassword.loading = false;
            changePassword.response = initialState.changePassword.response;
            changePassword.error = payload;
        },
    },
});

export const {
    setRefreshToken,
    login,
    loginSuccess,
    loginFailed,
    register,
    registerSuccess,
    registerFailed,
    verification,
    verificationSuccess,
    verificationFailed,
    resendVerification,
    resendVerificationSuccess,
    resendVerificationFailed,
    updateKyc,
    updateKycSuccess,
    updateKycFailed,
    forgotPassword,
    forgotPasswordSuccess,
    forgotPasswordFailed,
    changePassword,
    changePasswordSuccess,
    changePasswordFailed,
} = authSlice.actions;

export const forgotPasswordFn = (values: { email: string }, cb?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(forgotPassword());
        const response = await request({
            method: "post",
            url: "/merchant/request-merchant-password-change",
            data: values,
        });
        console.log(response);

        dispatch(forgotPasswordSuccess(response.data));
        // window.location.assign(routes.CHANGE_PASSWORD);
        toast.success(response.data.message);
        if (cb) {
            cb();
        }

        console.log(response);
    } catch (error) {
        dispatch(forgotPasswordFailed(error?.response?.data || error.response));
    }
};

export const changePasswordFn =
    (
        values: {
            resetCode: string;
            password: string;
        },
        cb?: () => void
    ) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(changePassword());
            const response = await request({
                method: "post",
                url: "/merchant/change-merchant-password",
                data: values,
            });
            console.log(response);

            dispatch(changePasswordSuccess(response.data));
            toast.success(response.data.message);

            // window.location.assign("/");
            if (cb) {
                cb();
            }

            console.log(response);
        } catch (error) {
            dispatch(changePasswordFailed(error?.response?.data || error.response));
        }
    };

export const loginUserFn = (values: LoginValuesTypes, cb?: (payload?: any) => void) => async (dispatch: Dispatch) => {
    dispatch(login());
    try {
        const response = await request({
            method: "post",
            url: "/merchant/login",
            data: values,
        });

        if (response?.data?.requiresVerification) {
            if (cb) {
                cb(values);
            }
        } else {
            dispatch(loginSuccess(response.data));

            if (response.data.merchant) {
                localStorage.setItem("user", JSON.stringify(response.data.merchant));
            }

            localStorage.setItem(config.ACCESS_TOKEN, response.headers["x-access-token"]);
            localStorage.setItem(config.ACCOUNT_MODE, response.data.merchant.mode);

            if (cb) {
                cb();
            }
        }
    } catch (error) {
        dispatch(loginFailed(error?.response?.data || error.response));
    }
};

export const logoutFn = () => async (dispatch: Dispatch) => {
    localStorage.removeItem(config.ACCESS_TOKEN);
    localStorage.removeItem(config.ACCOUNT_MODE);
    localStorage.removeItem("user");
    window.location.reload();
};

export const registerUserFn = (values: RegisterValuesTypes) => async (dispatch: Dispatch) => {
    try {
        dispatch(register());
        const response = await request({
            method: "post",
            url: "/merchant",
            data: { ...values, merchantType: "PLATINUM" },
        });
        dispatch(registerSuccess(response.data));
        window.location.assign(`${routes.VERIFICATION}/${values.email}`);
        console.log(response);
    } catch (error) {
        dispatch(registerFailed(error?.response?.data || error.response));
    }
};

export const verifyUserFn =
    (values: VerificationValuesTypes, cb: { (id: string): any; (id: string): void }) => async (dispatch: Dispatch) => {
        try {
            dispatch(verification());
            const response = await request({
                method: "put",
                url: "/merchant/verify",
                data: values,
            });
            console.log(response);

            dispatch(verificationSuccess(response.data));
            cb(response.data.id);
            console.log(response);
        } catch (error) {
            dispatch(verificationFailed(error?.response?.data || error.response));
        }
    };

export const resendVerificationFn = (email: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(verification());
        const response = await request({
            method: "post",
            url: "/merchant/resend-activation-code",
            data: {
                email,
            },
        });
        // console.log(response);

        dispatch(verificationSuccess(response.data));
        toast.success(response.data.message || "Code sent. Check your email");
    } catch (error) {
        dispatch(verificationFailed(error?.response?.data || error.response));
    }
};

export const updateKycFn =
    ({ image, imageTitle, bvn, cb }: Record<string, any>) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(updateKyc());

            const formData = new FormData();

            formData.set("directorsBVN", bvn);
            formData.append("cac_pack", image, imageTitle);

            const response = await request({
                method: "put",
                data: formData,
                url: "/merchant/complete-merchant-registration",
                headers: { "Content-Type": "multipart/form-data" },
            });

            dispatch(updateKycSuccess(response.data));

            cb();
        } catch (error) {
            console.log("What happned: ", error);
            dispatch(updateKycFailed(error?.response?.data || error.response));
        }
    };

export const selectAuthState = (state: any) => state.auth;

export default authSlice.reducer;
