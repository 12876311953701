import * as React from "react";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import Header from "components/Header/Header";
import NavSection from "components/CardNav/CardNav";
import styles from "./Settings.module.css";
import routes from "routing/routes";

const navLinks = [
  {
    label: "Profile",
    route: routes.SETTINGS,
  },
  {
    label: "Security",
    route: routes.SECURITY,
  },
  {
    label: "Account Verification",
    route: routes.ACCOUNT_VERIFICATION,
  },
  // {
  //   label: "Notifications",
  //   route: routes.NOTIFICATIONS,
  // },
  {
    label: "Developer Keys",
    route: routes.DEVELOPER_KEYS,
  },
];

const Settings: React.FC = ({ children }) => {
  return (
    <DashboardLayout>
      <Header>Settings</Header>
      <div className={`mt-4 ${styles.content__container}`}>
        <NavSection links={navLinks} />
        <div className={styles.content}>{children}</div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
