import React, { useEffect, Fragment, useState } from "react";
import Loading from "components/Loading/Loading";
import { useSelector, useDispatch } from "react-redux";
import { getAccessKeysFn, selectSettingsState, updateProfileFn, getProfileFn } from "slices/settingsSlice";

import Settings from "../Settings";
import Config from "../../../constants/api";

const DeveloperKeysScreen: React.FC = () => {
    const [url, setUrl] = useState("");

    const dispatch = useDispatch();
    const profileState = useSelector(selectSettingsState);
    const userState = useSelector(selectSettingsState);
    const { data: user } = userState.getProfile;

    const currentMode = localStorage.getItem(Config.ACCOUNT_MODE) || "SANDBOX";

    useEffect(() => {
        dispatch(getAccessKeysFn());
        dispatch(getProfileFn());
    }, [dispatch]);

    const { privateKey, publicKey } = profileState.getAccessKeys.data;

    return (
        <Settings>
            {!publicKey && profileState.getAccessKeys.loading ? (
                <Loading />
            ) : (
                <Fragment>
                    <div>
                        <p className="settings__lightText">Public Key</p>
                        <p>{publicKey}</p>
                    </div>
                    <div>
                        <p className="settings__lightText">Secret Key</p>
                        <p>{privateKey}</p>
                    </div>

                    <p className="settings__lightText">Callback URL</p>
                    <div className="input-group mb-3 w-50">
                        <input
                            type="text"
                            className="form-control"
                            aria-describedby="callbackURL"
                            aria-label="Enter your callback URL"
                            placeholder="Enter your callback URL"
                            onChange={(e) => setUrl(e.target.value)}
                            value={
                                url || (currentMode === "PRODUCTION" ? user.callbackURL : user.sandboxCallbackURL) || ""
                            }
                        />
                        <div className="input-group-append">
                            <button
                                type="button"
                                id="callbackURL"
                                className="btn btn-outline-secondary"
                                onClick={(e) => {
                                    e.preventDefault();

                                    const key = currentMode === "PRODUCTION" ? "callbackURL" : "sandboxCallbackURL";
                                    dispatch(updateProfileFn({ [key]: url }));
                                }}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </Fragment>
            )}
        </Settings>
    );
};

export default DeveloperKeysScreen;
