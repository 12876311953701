import * as React from "react";
import styles from "./Button.module.css";
import { Link } from "react-router-dom";

interface PrimaryButtonProps {
  title: string;
  size?: "sm" | "lg";
  block?: boolean;
  className?: string;
  type?: "submit" | "button";
  disabled?: boolean;
  onClick?: () => void;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  title,
  size,
  block,
  type,
  className,
  onClick,
}) => {
  let classes = `${styles.primaryBtn} btn ${className ? className : ""}`;

  if (size) {
    classes = `btn-${size} ${classes}`;
  }
  if (block) {
    classes = `btn-block ${classes}`;
  }

  return (
    <button onClick={onClick} className={`${classes}`} type={type}>
      {title}
    </button>
  );
};

interface OutlineButtonProps {
  title: string;
  link?: string;
  size?: "sm" | "lg";
  block?: boolean;
  className?: string;
  onClick?: () => void;
}

export const OutlineButton: React.FC<OutlineButtonProps> = ({
  title,
  link,
  size,
  block,
  className,
  onClick,
}) => {
  let classes = `${styles.outlineBtn} btn btn-outline ${
    className ? className : ""
  }`;
  if (size) {
    classes = `btn-${size} ` + classes;
  }
  if (block) {
    classes = `btn-block ${classes}`;
  }
  if (link) {
    return (
      <Link className={classes} to={link}>
        {title}
      </Link>
    );
  }
  return (
    <button onClick={onClick} type="button" className={classes}>
      {title}
    </button>
  );
};
