import React from "react";
import Settings from "../Settings";
import { useDispatch } from "react-redux";
import { forgotPasswordFn } from "slices/authSlice";

const SecurityScreen: React.FC = () => {
  const dispatch = useDispatch();

  const changePassword = () => {
    const user = JSON.parse(localStorage.getItem("user") || "");
    dispatch(forgotPasswordFn({ email: user.email }));
  };
  return (
    <Settings>
      <div className="d-flex">
        <div>
          <p className="mb-0">Security</p>
          <p className="settings__lightText">Change your password</p>
          {/* <p className="settings__lightText">Last changed Sep 4, 2020</p> */}
        </div>
        <div>
          <button
            onClick={changePassword}
            type="button"
            className="btn btn-outline-secondary ml-5"
          >
            Change password
          </button>
        </div>
      </div>
    </Settings>
  );
};

export default SecurityScreen;
