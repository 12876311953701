import * as React from "react";
import styles from "./Table.module.css";

interface TableProps {
  headers: Array<string>;
  noData?: boolean;
}

const Table: React.FC<TableProps> = ({ headers, children, noData }) => {
  const renderHeaders = () => {
    const mappedHeaders = headers?.map((each, index) => {
      return <th key={index}>{each}</th>;
    });
    return (
      <thead className={styles.table__thead}>
        <tr>{mappedHeaders}</tr>
      </thead>
    );
  };
  if (noData) {
    return (
      <div>
        <p>No information to display</p>
      </div>
    );
  }
  return (
    <table className="table table-striped table-borderless">
      {renderHeaders()}
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
