import React from "react";
import Header from "components/Header/Header";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import styles from "./Wallet.module.css";
import { numberWithCommas } from "utils/helpers";
import AddWalletImg from "assets/images/addWallet.svg";
import { fundWalletFn, getWalletBalanceFn } from "slices/walletSlice";
import { useDispatch } from "react-redux";
import BankDetails from "components/BankDetails/BankDetails";
import config from "constants/api";

interface WalletProps {
  data: {
    availableBalance: string;
    bookedBalance: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
  };
  openSideContentFn: (content: string) => void;
}

const Wallet: React.FC<WalletProps> = ({ data, openSideContentFn }) => {
  const dispatch = useDispatch();
  const {
    availableBalance,
    bookedBalance,
    accountName,
    accountNumber,
    bankName,
  } = data;

  return (
    <div className={styles.wallet}>
      <div className="row mb-4">
        <div className="col">
          <Header>
            Wallet{" "}
            <img
              onClick={() => openSideContentFn("createWallet")}
              className={styles.wallet__headerIcon}
              src={AddWalletImg}
              alt=""
            />
          </Header>
        </div>

        <div className="col d-flex justify-content-end">
          {localStorage.getItem(config.ACCOUNT_MODE) === "SANDBOX" ? (
            <PrimaryButton
              onClick={() =>
                dispatch(fundWalletFn(() => dispatch(getWalletBalanceFn())))
              }
              className="mr-2"
              title="Fund Wallet"
              // disabled
            />
          ) : null}
          <OutlineButton
            title="Withdraw"
            onClick={() => openSideContentFn("walletWithdraw")}
          />
        </div>
      </div>

      <div className={styles.wallet__content}>
        <div>
          <p className={styles.wallet__amountTitle}>Available balance</p>
          <h3 className={styles.wallet__amount}>
            &#x20A6;{numberWithCommas(availableBalance)}
          </h3>
        </div>
        <div className={styles.wallet__vl} />
        <div>
          <p className={styles.wallet__amountTitle}>Book balance</p>
          <h3 className={styles.wallet__amount}>
            &#x20A6;{numberWithCommas(bookedBalance)}
          </h3>
        </div>

        <BankDetails
          accountName={accountName}
          accountNumber={accountNumber}
          bankName={bankName}
        />
      </div>
    </div>
  );
};

export default Wallet;
