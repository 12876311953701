import * as React from "react";
import AuthLayout from "layouts/Auth/Auth";
import Clipboard from "assets/images/review-board.svg";
import { Link } from "react-router-dom";
import routes from "routing/routes";

const AccountReviewScreen: React.FC = () => {
  return (
    <AuthLayout>
      <div className="text-center">
        <img src={Clipboard} alt="" />
        <h3 className="my-3">Successful</h3>
        <p>
          Your Documents have been uploaded and Pending Review, we will get back
          shortly via your email.
        </p>
        <Link to={routes.HOME} className="btn btn-outline-secondary">
          Back to Dashboard
        </Link>
      </div>
    </AuthLayout>
  );
};

export default AccountReviewScreen;
