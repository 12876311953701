import { createSlice } from "@reduxjs/toolkit";
// import config from "constants/api";
import { toast } from "react-toastify";
import request from "constants/requests";

interface createWalletValues {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  dateOfBirth: string;
  bvn?: string;
}

// interface initialStateTypes {
//   wallets: { loading: boolean; data: Array<object>; error: object };
//   createWallet: {
//     loading: boolean;
//     data: object;
//     error: object;
//   };
//   balance: {
//     loading: boolean;
//     data: object;
//     error: object;
//   };
//   fundWallet: {
//     loading: boolean;
//     data: object;
//     error: object;
//   };
// }

const initialState = {
  wallets: { loading: true, data: [], error: {}, meta: {} },
  createWallet: {
    loading: false,
    data: {},
    error: {},
  },
  balance: {
    loading: true,
    data: {},
    error: {},
  },
  fundWallet: {
    loading: false,
    data: {},
    error: {},
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    getWallets: (state) => {
      let { wallets } = state;
      wallets.loading = true;
      wallets.error = initialState.wallets.error;
      // wallets.data = initialState.wallets.data;
    },
    getWalletsSuccess: (state, { payload }) => {
      let { wallets } = state;
      wallets.loading = false;
      wallets.data = payload.data;
      wallets.meta = payload.meta;
      wallets.error = initialState.wallets.error;
    },
    getWalletsFailed: (state, { payload }) => {
      let { wallets } = state;
      wallets.loading = false;
      wallets.data = initialState.wallets.data;
      wallets.error = payload;
      wallets.meta = {};
    },
    createWallet: (state) => {
      let { createWallet } = state;
      createWallet.loading = true;
      createWallet.error = initialState.createWallet.error;
      createWallet.data = initialState.createWallet.data;
    },
    createWalletSuccess: (state, { payload }) => {
      let { createWallet } = state;
      createWallet.loading = initialState.createWallet.loading;
      createWallet.data = payload;
      createWallet.error = initialState.createWallet.error;
    },
    createWalletFailed: (state, { payload }) => {
      let { createWallet } = state;
      createWallet.loading = initialState.createWallet.loading;
      createWallet.data = initialState.createWallet.data;
      createWallet.error = payload;
    },
    getBalance: (state) => {
      let { balance } = state;
      balance.loading = true;
      balance.error = initialState.balance.error;
      // balance.data = initialState.balance.data;
    },
    getBalanceSuccess: (state, { payload }) => {
      let { balance } = state;
      balance.loading = false;
      balance.data = payload;
      balance.error = initialState.balance.error;
    },
    getBalanceFailed: (state, { payload }) => {
      let { balance } = state;
      balance.loading = false;
      balance.data = initialState.balance.data;
      balance.error = payload;
    },
    fundWallet: (state) => {
      let { fundWallet } = state;
      fundWallet.loading = true;
      fundWallet.error = initialState.fundWallet.error;
      fundWallet.data = initialState.fundWallet.data;
    },
    fundWalletSuccess: (state, { payload }) => {
      let { fundWallet } = state;
      fundWallet.loading = initialState.fundWallet.loading;
      fundWallet.data = payload;
      fundWallet.error = initialState.fundWallet.error;
    },
    fundWalletFailed: (state, { payload }) => {
      let { fundWallet } = state;
      fundWallet.loading = initialState.fundWallet.loading;
      fundWallet.data = initialState.fundWallet.data;
      fundWallet.error = payload;
    },
  },
});

export const {
  getWallets,
  getWalletsSuccess,
  getWalletsFailed,
  createWallet,
  createWalletSuccess,
  createWalletFailed,
  getBalance,
  getBalanceSuccess,
  getBalanceFailed,
  fundWallet,
  fundWalletSuccess,
  fundWalletFailed,
} = walletSlice.actions;

export const getWalletsFn = ({ page }: { page?: number }) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(getWallets());
    const response = await request({
      method: "get",
      url: "/wallet",
      params: {
        page,
      },
    });
    dispatch(
      getWalletsSuccess({
        data: response.data.wallets,
        meta: response.data.metadata,
      })
    );
  } catch (error) {
    dispatch(getWalletsFailed(error.response.data || error.response));
  }
};

export const getWalletBalanceFn = () => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(getBalance());
    const response = await request({ method: "get", url: "/merchant/wallet" });
    dispatch(getBalanceSuccess(response.data.wallet));
  } catch (error) {
    dispatch(getBalanceFailed(error.response.data || error.response));
  }
};

export const createWalletFn = (
  values: createWalletValues,
  cb?: () => void
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(createWallet());
    const response = await request({
      method: "post",
      url: "wallet",
      data: values,
    });
    dispatch(createWalletSuccess(response.data));
    toast.success(response.data.message || "Successfully created wallet");
    if (cb) {
      cb();
    }
    console.log(response);
  } catch (error) {
    dispatch(createWalletFailed(error.response.data || error.response));
    toast.error(error.response.data || error.response.message);
  }
};
export const fundWalletFn = (cb: () => void) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(fundWallet());
    const response = await request({
      url: "/merchant/fund-wallet",
      method: "post",
      data: { amount: 10000 },
    });
    dispatch(fundWalletSuccess(response.data));
    toast.success("Wallet funded with ₦10,000");
    console.log(response.data);
    cb();
  } catch (error) {
    dispatch(fundWalletFailed(error.response.data || error.response));
    toast.error(error.response.data || error.response.message);
  }
};

export const selectWalletState = (state: any) => state.wallet;

export default walletSlice.reducer;
