import React, { Fragment, useState } from "react";
import AuthLayout from "layouts/Auth/Auth";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import { Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateKycFn, selectAuthState } from "slices/authSlice";
import routes from "routing/routes";
import TextInput from "components/TextInput/TextInput";
import { mixed, object, string } from "yup";

const KYCScreen: React.FC = ({ history, match }: any) => {
    const [image, setImage] = useState({});
    const [imageName, setImageName] = useState("");

    const dispatch = useDispatch();
    const authState = useSelector(selectAuthState);
    const { loading: kycLoading } = authState.kyc;

    const submitKYC = (values: { bvn: string }) => {
        const merchantId = match.params.id;

        console.log({ values });

        dispatch(
            updateKycFn({
                image: image,
                imageTitle: imageName,
                cb: () => history.push(routes.ACCOUNT_REVIEW),
                merchantId,
                bvn: values.bvn,
            })
        );
    };

    const fileSelectedHandler = (event: any) => {
        if (event.target.files[0]) {
            setImage(event.target.files[0]);
            setImageName(event.target.files[0].name);
        }
    };

    return (
        <AuthLayout title="Director's Details">
            <Formik
                onSubmit={submitKYC}
                initialValues={{ bvn: "", file: null }}
                validationSchema={object().shape({
                    file: mixed().required(),
                    bvn: string().length(11, "Must be 11 characters"),
                })}
            >
                {({ touched, errors }) => (
                    <Form>
                        <Field name="bvn" placeholder="BVN" type="text" component={TextInput} />

                        <div className="form-group">
                            <div className="custom-file">
                                <input
                                    id="cac"
                                    type="file"
                                    className="custom-file-input"
                                    onChange={fileSelectedHandler}
                                    accept="image/png, image/jpeg,image/jpg,.pdf"
                                />
                                <label className="custom-file-label" htmlFor="cac">
                                    Choose file
                                </label>
                                {touched.file && errors.file && <div className="invalid-feedback">{errors.file}</div>}
                            </div>
                        </div>

                        {kycLoading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <PrimaryButton type="submit" block size="lg" title="Submit" />
                                <OutlineButton
                                    block
                                    size="lg"
                                    //   link={routes.REGISTER}
                                    title="Skip"
                                />
                            </Fragment>
                        )}
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default withRouter(KYCScreen);
