import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "../screens/PageNotFound";
import LoginScreen from "../screens/Login/Login";
import RegisterScreen from "../screens/Register/Register";
import routes from "./routes";
import VerificationScreen from "../screens/Verification/Verification";
import HomeScreen from "../screens/Home/Home";
import TransactionsScreen from "../screens/Transactions/Transactions";
// import SendMoneyScreen from "./screens/SendMoney/SendMoney";
// import SettingsScreen from "../screens/Settings/Settings";
import CustomersScreen from "../screens/Customers/Customers";
import PrivateRoute from "./PrivateRoute";
import ViaWallet from "screens/SendMoney/components/Wallet/Wallet";
import ViaBank from "screens/SendMoney/components/Bank/Bank";
import ViaMobileMoney from "screens/SendMoney/components/MobileMoney/MobileMoney";
import ProfileScreen from "screens/Settings/Profile/Profile";
import SecurityScreen from "screens/Settings/Security/Security";
import AccountVerificationScreen from "screens/Settings/AccountVerification/AccountVerification";
import NotificationsScreen from "screens/Settings/Notifications/Notifications";
import DeveloperKeysScreen from "screens/Settings/DeveloperKeys/DeveloperKeys";
import KYCScreen from "screens/Kyc/Kyc";
import AccountReviewScreen from "screens/AccountReview/AccountReview";
import ChangePasswordScreen from "screens/ChangePassword/ChangePassword";
import ForgotPasswordScreen from "screens/ForgotPassword/ForgotPassword";

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoute path={routes.VIA_MOBILE_MONEY} component={ViaMobileMoney} />
                <PrivateRoute path={routes.VIA_BANK} component={ViaBank} />
                <PrivateRoute path={routes.VIA_WALLET} component={ViaWallet} />
                <PrivateRoute path={routes.DEVELOPER_KEYS} component={DeveloperKeysScreen} />
                <PrivateRoute path={routes.NOTIFICATIONS} component={NotificationsScreen} />
                <PrivateRoute path={routes.ACCOUNT_VERIFICATION} component={AccountVerificationScreen} />
                <PrivateRoute path={routes.SECURITY} component={SecurityScreen} />
                <PrivateRoute path={routes.PROFILE} component={ProfileScreen} />
                <PrivateRoute path={routes.CUSTOMERS} component={CustomersScreen} />
                <PrivateRoute path={routes.SETTINGS} component={ProfileScreen} />
                <PrivateRoute path={routes.SEND_MONEY} component={ViaWallet} />
                <PrivateRoute path={routes.TRANSACTIONS} component={TransactionsScreen} />
                <PrivateRoute path={routes.HOME} component={HomeScreen} />
                <Route path={routes.ACCOUNT_REVIEW} component={AccountReviewScreen} />
                <Route path={routes.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
                <Route path={routes.CHANGE_PASSWORD} component={ChangePasswordScreen} />
                <Route path={`${routes.KYC}/:id`} component={KYCScreen} />
                <Route path={`${routes.VERIFICATION}/:email`} component={VerificationScreen} />
                <Route path={routes.VERIFICATION} component={VerificationScreen} />
                <Route path={routes.REGISTER} component={RegisterScreen} />
                <Route exact path={routes.LOGIN} component={LoginScreen} />
                <Route component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
