import React from "react";
import LogoImg from "assets/images/logo.svg";
import styles from "./Navbar.module.css";
import { useDispatch } from "react-redux";
import { setAccountModeFn } from "slices/configSlice";
import config from "constants/api";
import { Link } from "react-router-dom";
import routes from "routing/routes";

const Navbar: React.FC = () => {
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem("user") || "");

    return (
        <div className={styles.navbar}>
            <div className={`${styles.navbar__content} container`}>
                <div>
                    <Link to={routes.HOME}>
                        <img className={styles.navbar__logo} src={LogoImg} alt="" />
                    </Link>
                </div>
                <div className="custom-control custom-switch">
                    <input
                        onChange={(e) => {
                            if (!e.target.checked) {
                                dispatch(
                                    setAccountModeFn("SANDBOX", () => {
                                        localStorage.setItem(config.ACCOUNT_MODE, "SANDBOX");
                                        window.location.reload();
                                    })
                                );
                            } else {
                                dispatch(
                                    setAccountModeFn("PRODUCTION", () => {
                                        localStorage.setItem(config.ACCOUNT_MODE, "PRODUCTION");
                                        window.location.reload();
                                    })
                                );
                            }
                        }}
                        id="testMode"
                        type="checkbox"
                        className="custom-control-input"
                        checked={localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION" ? true : false}
                    />
                    <label className="custom-control-label" htmlFor="testMode">
                        {localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION" ? "Live Mode On" : "Test Mode On"}
                    </label>
                </div>
                <div className={styles.navbar__rightContent}>
                    <div className={styles.navbar__initials}>
                        {user.lastName.slice(0, 1) || ""}
                        {user.firstName.slice(0, 1) || ""}
                    </div>
                    <span className={styles.navbar__username}>
                        {user.lastName} {user.firstName}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
