import React, { Fragment, useEffect } from "react";
import AuthLayout from "layouts/Auth/Auth";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import routes from "routing/routes";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { loginUserFn, selectAuthState, LoginValuesTypes } from "slices/authSlice";
import config from "constants/api";
import TextInput from "components/TextInput/TextInput";
import Loading from "components/Loading/Loading";
import { Link } from "react-router-dom";
import styles from "./Login.module.css";
import { useHistory } from "react-router-dom";

const LoginScreen: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem(config.ACCESS_TOKEN) !== null) {
            window.location.assign(routes.HOME);
        }
    }, []);

    const authState = useSelector(selectAuthState);
    const { loading: loginLoading } = authState.login;

    const submitForm = (values: LoginValuesTypes) => {
        dispatch(
            loginUserFn(values, (payload) => {
                if (!payload) {
                    history.push(routes.HOME);
                } else {
                    history.push(`${routes.VERIFICATION}/${payload.email}`);
                }
            })
        );
    };

    return (
        <AuthLayout title="Login">
            <Formik initialValues={{ email: "", password: "" }} onSubmit={submitForm} validationSchema={LoginSchema}>
                {() => (
                    <Form>
                        <Field name="email" placeholder="Email Address" type="text" component={TextInput} />
                        <Field
                            name="password"
                            type="password"
                            component={TextInput}
                            placeholder="Password"
                            autoComplete="current-password"
                        />
                        <Link className={styles.login__forgotPassword} to={routes.FORGOT_PASSWORD}>
                            Forgot Password?
                        </Link>

                        {loginLoading ? (
                            <Loading />
                        ) : (
                            <Fragment>
                                <PrimaryButton type="submit" block size="lg" title="Login" />
                                <OutlineButton block size="lg" link={routes.REGISTER} title="Sign Up" />
                            </Fragment>
                        )}
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default LoginScreen;

const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(6, "Too Short!").max(70, "Too Long!").required("Required"),
});
