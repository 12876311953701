import { createSlice } from "@reduxjs/toolkit";
import request from "constants/requests";
import { toast } from "react-toastify";

const initialState = {
  customers: {
    loading: true,
    data: [],
    error: {},
    meta: {},
  },
  customerData: {
    loading: false,
    data: {},
    error: {},
  },
  creditWallet: {
    loading: false,
    data: {},
    error: {},
  },
  debitWallet: {
    loading: false,
    data: {},
    error: {},
  },
  freezeWallet: {
    loading: false,
    data: {},
    error: {},
  },
  unfreezeWallet: {
    loading: false,
    data: {},
    error: {},
  },
};

const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    getCustomers: (state) => {
      let { customers } = state;
      customers.loading = true;
      // customers.data = [];
      customers.error = {};
    },
    getCustomersSuccess: (state, { payload }) => {
      let { customers } = state;
      customers.loading = false;
      customers.data = payload.data;
      customers.meta = payload.meta;
      customers.error = {};
    },
    getCustomersFailed: (state, { payload }) => {
      let { customers } = state;
      customers.loading = false;
      customers.data = [];
      customers.error = payload;
    },
    getSingleCustomer: (state) => {
      let { customerData } = state;
      customerData.loading = true;
      customerData.data = {};
      customerData.error = {};
    },
    getSingleCustomerSuccess: (state, { payload }) => {
      let { customerData } = state;
      customerData.loading = false;
      customerData.data = payload;
      customerData.error = {};
    },
    getSingleCustomerFailed: (state, { payload }) => {
      let { customerData } = state;
      customerData.loading = false;
      customerData.data = {};
      customerData.error = payload;
    },
    creditWallet: (state) => {
      let { creditWallet } = state;
      creditWallet.loading = true;
      creditWallet.data = {};
      creditWallet.error = {};
    },
    creditWalletSuccess: (state, { payload }) => {
      let { creditWallet } = state;
      creditWallet.loading = false;
      creditWallet.data = payload;
      creditWallet.error = {};
    },
    creditWalletFailed: (state, { payload }) => {
      let { creditWallet } = state;
      creditWallet.loading = false;
      creditWallet.data = {};
      creditWallet.error = payload;
    },
    debitWallet: (state) => {
      let { debitWallet } = state;
      debitWallet.loading = true;
      debitWallet.data = {};
      debitWallet.error = {};
    },
    debitWalletSuccess: (state, { payload }) => {
      let { debitWallet } = state;
      debitWallet.loading = false;
      debitWallet.data = payload;
      debitWallet.error = {};
    },
    debitWalletFailed: (state, { payload }) => {
      let { debitWallet } = state;
      debitWallet.loading = false;
      debitWallet.data = {};
      debitWallet.error = payload;
    },
    freezeWallet: (state) => {
      let { freezeWallet } = state;
      freezeWallet.loading = true;
      freezeWallet.data = {};
      freezeWallet.error = {};
    },
    freezeWalletSuccess: (state, { payload }) => {
      let { freezeWallet } = state;
      freezeWallet.loading = false;
      freezeWallet.data = payload;
      freezeWallet.error = {};
    },
    freezeWalletFailed: (state, { payload }) => {
      let { freezeWallet } = state;
      freezeWallet.loading = false;
      freezeWallet.data = {};
      freezeWallet.error = payload;
    },
    unfreezeWallet: (state) => {
      let { unfreezeWallet } = state;
      unfreezeWallet.loading = true;
      unfreezeWallet.data = {};
      unfreezeWallet.error = {};
    },
    unfreezeWalletSuccess: (state, { payload }) => {
      let { unfreezeWallet } = state;
      unfreezeWallet.loading = false;
      unfreezeWallet.data = payload;
      unfreezeWallet.error = {};
    },
    unfreezeWalletFailed: (state, { payload }) => {
      let { unfreezeWallet } = state;
      unfreezeWallet.loading = false;
      unfreezeWallet.data = {};
      unfreezeWallet.error = payload;
    },
  },
});

export const {
  getCustomers,
  getCustomersSuccess,
  getCustomersFailed,
  getSingleCustomer,
  getSingleCustomerSuccess,
  getSingleCustomerFailed,
  creditWallet,
  creditWalletSuccess,
  creditWalletFailed,
  debitWallet,
  debitWalletSuccess,
  debitWalletFailed,
  freezeWallet,
  freezeWalletSuccess,
  freezeWalletFailed,
  unfreezeWallet,
  unfreezeWalletSuccess,
  unfreezeWalletFailed,
} = customerSlice.actions;

export const getCustomersFn = ({ page }: { page?: number }) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(getCustomers());
    const response = await request({
      method: "get",
      url: "/customer",
    });

    dispatch(
      getCustomersSuccess({
        data: response.data.customers,
        meta: response.data.metadata,
        params: { page },
      })
    );
  } catch (error) {
    dispatch(getCustomersFailed(error.response.data || error.response));
  }
};

export const getSingleCustomerFn = (id: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(getSingleCustomer());
    const response = await request({
      method: "get",
      url: "/wallet/customer",
      params: {
        customerId: id,
      },
    });
    dispatch(getSingleCustomerSuccess(response.data.wallet));
  } catch (error) {
    dispatch(getSingleCustomerFailed(error.response.data || error.response));
  }
};

interface WalletUpdateType {
  reference?: string | number;
  amount: string;
  customerId: string;
}

export const creditWalletFn = (
  values: WalletUpdateType,
  cb: (id: string) => void
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(creditWallet());
    const response = await request({
      method: "post",
      url: "/wallet/credit",
      data: values,
    });
    dispatch(creditWalletSuccess(response.data));
    toast.success(response.data.message || "Success");
    if (cb) {
      cb(values.customerId);
    }
  } catch (error) {
    dispatch(creditWalletFailed(error.response.data || error.response));
  }
};

export const debitWalletFn = (
  values: WalletUpdateType,
  cb: (id: string) => void
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  try {
    dispatch(debitWallet());
    const response = await request({
      method: "post",
      url: "/wallet/debit",
      data: values,
    });
    dispatch(debitWalletSuccess(response.data));
    toast.success(response.data.message || "Success");
    if (cb) {
      cb(values.customerId);
    }
  } catch (error) {
    dispatch(debitWalletFailed(error.response.data || error.response));
  }
};

export const freezeWalletFn = (customerId: string, cb: () => void) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(freezeWallet());
    const response = await request({
      method: "post",
      url: "/wallet/close",
      data: {
        customerId,
      },
    });
    dispatch(freezeWalletSuccess(response.data));
    toast.success(response.data.message || "Success");
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch(freezeWalletFailed(error.response.data || error.response));
  }
};

export const unfreezeWalletFn = (customerId: string, cb: () => void) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    dispatch(unfreezeWallet());
    const response = await request({
      method: "post",
      url: "/wallet/enable",
      data: {
        customerId,
      },
    });
    dispatch(unfreezeWalletSuccess(response.data));
    toast.success(response.data.message || "Success");
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch(unfreezeWalletFailed(error.response.data || error.response));
  }
};

export const selectCustomerState = (state: any) => state.customers;

export default customerSlice.reducer;
