import React, { useEffect, useState } from "react";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./Transactions.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  getTransactionsFn,
  selectTransactionsState,
  getSingleTransactionFn,
} from "slices/transactionsSlice";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import TransactionDetail from "./components/TransactionDetail";

const TransactionsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const transactionsState = useSelector(selectTransactionsState);
  const {
    data: transactionsData,
    meta: transactionsMeta,
  } = transactionsState.transactions;
  const transaction = transactionsState.singleTransaction;

  const [currentPage, setCurrentPage] = useState(transactionsMeta.page);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const fetchTransactions = () => {
    dispatch(getTransactionsFn({ page: currentPage }));
  };

  useEffect(fetchTransactions, [currentPage]);

  const renderRows = () => {
    const rows = transactionsData.map((each: any, index: number) => {
      return (
        <tr
          onClick={() => getTransaction(each.reference)}
          style={{ cursor: "pointer" }}
          key={index}
        >
          <td>{new Date(each.createdAt).toLocaleDateString("en-gb")}</td>
          <td>&#8358;{numberWithCommas(each.amount)}</td>
          <td>{each.type}</td>
          <td>{each.description}</td>
        </tr>
      );
    });
    return rows;
  };

  const getTransaction = (reference: string) => {
    dispatch(
      getSingleTransactionFn(reference, () => {
        openModal("transactionDetail");
      })
    );
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "transactionDetail") {
      return <TransactionDetail transaction={transaction} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {transactionsState.transactions.data.length < 1 &&
        transactionsState.transactions.loading ? (
          <Loading />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Transactions</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Transactions
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {transactionsMeta.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getTransaction(searchValue);
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchTransaction">
                    Search transaction
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div
                        style={{
                          borderRightColor: "transparent",
                          backgroundColor: "transparent",
                        }}
                        className="input-group-text"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                      style={{ borderLeftColor: "transparent" }}
                      type="text"
                      className="form-control"
                      id="searchTransaction"
                      placeholder="Search with transaction Reference"
                    />
                  </div>
                </div>
              </form>

              <Table
                noData={transactionsData.length === 0 ? true : false}
                headers={["created", "amount", "type", "description"]}
              >
                {renderRows()}
              </Table>

              <nav aria-label="Page navigation example">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  // breakLabel={"..."}
                  // breakClassName={"break-me"}
                  pageCount={transactionsMeta.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                />
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default TransactionsScreen;
