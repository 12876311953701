import * as React from "react";
import styles from "./Wallet.module.css";
import { PrimaryButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserByPhoneFn,
  selectSendMoneyState,
  clearUserByPhoneFn,
} from "slices/sendMoneySlice";
import { creditWalletFn } from "slices/customersSlice";
import TextInput from "components/TextInput/TextInput";

const ViaWallet: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectSendMoneyState);
  const { customer } = bankState.userByPhone.data || {};
  const { loading: customerLoading } = bankState.userByPhone;

  const fetchUser = (phoneNumber: string) => {
    if (Number(phoneNumber) && phoneNumber.length === 11) {
      dispatch(getUserByPhoneFn(phoneNumber));
    } else if (phoneNumber.length !== 11) {
      dispatch(clearUserByPhoneFn());
    }
  };

  const sendMoney = (
    values: { phoneNumber: string; amount: string },
    clearForm: () => void
  ) => {
    dispatch(
      creditWalletFn(
        {
          amount: values.amount,
          customerId: customer.id,
          reference: Date.now(),
        },
        () => {
          clearForm();
          dispatch(clearUserByPhoneFn());
        }
      )
    );
  };

  const renderVerifiedPhone = () => {
    if (customerLoading) {
      return "verifiying...";
    } else if (customer) {
      return `${customer.lastName} ${customer.firstName}`;
    } else {
      return null;
    }
  };

  return (
    <SendMoney>
      <Formik
        initialValues={{
          phoneNumber: "",
          amount: "",
        }}
        validationSchema={WalletSchema}
        onSubmit={(values, actions) => {
          sendMoney(values, () => actions.resetForm());
        }}
      >
        {(props) => (
          <div className={styles.viaWallet}>
            <h4 className={styles.viaWallet__title}>
              Enter recipients details
            </h4>
            <Form>
              <Field
                onBlur={() => {
                  fetchUser(props.values.phoneNumber);
                }}
                name="phoneNumber"
                placeholder="Phone Number"
                type="tel"
                component={TextInput}
                subtext={renderVerifiedPhone()}
              />
              <Field
                name="amount"
                placeholder="Amount"
                type="text"
                component={TextInput}
              />
              <PrimaryButton type="submit" title="Send Money" size="lg" block />
            </Form>
          </div>
        )}
      </Formik>
    </SendMoney>
  );
};

export default ViaWallet;

const WalletSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  amount: Yup.string().required("Required"),
});
