import React from "react";
import Settings from "../Settings";

const NotificationsScreen: React.FC = () => {
  return (
    <Settings>
      <div>Notifications</div>
    </Settings>
  );
};

export default NotificationsScreen;
