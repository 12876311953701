import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "slices/authSlice";
import walletReducer from "slices/walletSlice";
import customersReducer from "slices/customersSlice";
import TransactionsReducer from "slices/transactionsSlice";
import SettingsReducer from "slices/settingsSlice";
import SendMoneyReducer from "slices/sendMoneySlice";
import configReducer from "slices/configSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  customers: customersReducer,
  settings: SettingsReducer,
  transactions: TransactionsReducer,
  wallet: walletReducer,
  sendMoney: SendMoneyReducer,
  config: configReducer,
});

export default rootReducer;
