import * as React from "react";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";

export interface TransactionDetailProps {
  transaction: any;
}

const TransactionDetail: React.SFC<TransactionDetailProps> = ({
  transaction,
}) => {
  const {
    amount,
    balance_before,
    balance_after,
    reference,
    type,
    description,
    createdAt,
    metadata: customer,
  } = transaction.data;

  if (transaction.loading) {
    return <Loading />;
  }
  return (
    <div>
      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          {customer ? (
            <tr>
              <td>Full Name</td>
              <td align="right">{customer.customerName}</td>
            </tr>
          ) : null}
          <tr>
            <td>Description</td>
            <td align="right">{description}</td>
          </tr>
          <tr>
            <td>Date Created</td>
            <td align="right">
              {new Date(createdAt).toLocaleDateString("en-gb")}
            </td>
          </tr>
          <tr>
            <td>Type</td>
            <td align="right">{type}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td align="right">&#8358;{numberWithCommas(amount)}</td>
          </tr>
          <tr>
            <td>Balance Before</td>
            <td align="right">&#8358;{numberWithCommas(balance_before)}</td>
          </tr>
          <tr>
            <td>Balance After</td>
            <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
          </tr>
          <tr>
            <td>Reference</td>
            <td style={{ wordWrap: "break-word" }} align="right">
              {reference}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransactionDetail;
