import * as React from "react";
import styles from "./SectionSelector.module.css";
// import { NavLink } from "react-router-dom";

const SectionSelector: React.FC = () => {
  return (
    <section className="d-flex">
      <div>
        {/* <NavLink
          to="/"
          className={`${styles.section__itemActive} ${styles.section__item}`}
        > */}
        <div
          className={`${styles.section__itemActive} ${styles.section__item}`}
        >
          <span>Single Transfer</span>
          <i className="fas fa-caret-right"></i>
        </div>
        {/* </NavLink> */}
        {/* <NavLink to="/" className={styles.section__item}>
          <span>Bulk Transfer</span>
          <i className="fas fa-caret-right"></i>
        </NavLink> */}
      </div>
      <div className={styles.vl} />
    </section>
  );
};

export default SectionSelector;
